.rateTimeCase {
  margin: 0 0 10px 10px;
  padding: 15px;

  .configuration {
    display: flex;
    justify-content: left;

    .regions {
      //flex: 1;
      width: 36%;
      margin-right: 10px;
    }

    .selectors {
      margin-right: 10px;
      //flex: 1;
    }

    .action {
      margin-right: 0;
      margin-left: auto;

      .button {
        margin-left: 10px;
      }
    }
  }

  .row {

  }

  .selectors {
    margin-right: 10px;
  }

  .money {
    margin-right: 10px;
  }

  .time {
    margin-right: 10px;
  }

  .time {
    width: 200px;
    margin-bottom: 10px;
  }

  .money {
    width: 200px;
  }

  .inline {
    display: flex;

  }

  .divider {
    margin: 10px 0;
  }
}