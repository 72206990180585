.popUpNewFdl {

  .title {
    display: flex;
    flex-direction: row;
  }

  .content {
    padding: 10px;
    display: flex;
    flex-direction: row;

    .fields {
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
        flex-direction: row;

      }
    }

    .tablePreview {
      width: 800px;
      background-color: rgba(0, 0, 0, .5);
      margin: 0 0 0 10px;
      padding-top: 0;
      height: 100%;
      min-height: 315px;
      //overflow-y: scroll;

      table {
        height: 100%;
        //max-height: 400px;
        width: 100%;
        padding: 10px;


        /* width */
        *::-webkit-scrollbar {
          width: 7px;
          //display: none;
          display: inherit;
        }

        /* Track */
        *::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 10px;
        }

        /* Handle */
        *::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }

        /* Handle on hover */
        *::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        /*Inverte il colore dell`icona dell`orologio nei timepicker*/
        ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }

        thead {
          tr {
            display: block;

            &:first-child {
              th {
                border-top: 1px solid white;
              }
            }
          }
        }

        th, td {
          //min-width: 150px;
          border-bottom: 1px solid white;
          border-right: 1px solid white;
          //width: 300px;

          &:first-child {
            //border-top: 1px solid white;
            border-left: 1px solid white;

          }
        }

        tbody {
          display: block;
          height: 240px;
          overflow-y: auto; //set tbody to auto
        }

      }


      th {
        padding: 5px;
        background-color: #212121;
        position: sticky;
      }

      td {
        padding: 2px 5px;

      }


    }

    .textField {
      margin: 0 0 10px 0;
    }

    .error{
      animation: shake .1s linear;
      animation-iteration-count: 3;
    }

    @keyframes shake {
      0% {
        left: -5px;
      }
      100% {
        right: -5px;
      }
    }

    .littleTextField {
      width: 120px;
      margin: 0 0 10px 0;
    }
  }
}