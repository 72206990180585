.integration {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  width: 100%;
  height: 100%;

  .integration_general {
    flex: 2;
    height: 100%;
    margin: 0 7px 0 0;
    display: flex;
    flex-direction: column;
    min-height: 500px;

    .title {
      margin: 5px 0 5px 7px;
    }

    .columns {
      display: flex;
      flex-direction: row;

      .textfield {
        width: 100%;
        //margin: 18px 0 0 0;
      }

      .column1 {
        height: 100%;
        flex: 1;
        padding: 0 10px 0 10px;
      }

      .column2 {
        height: 100%;
        flex: 1.01;
        padding: 0px 10px 0 0;

      }

      .checkbox {
        /*width: 100%;*/
        margin: 10px 0;
        padding: 0;
      }

      .contractType {
        margin-top: 7px;
      }

      .closeIntegration {
        display: flex;
        flex-direction: row;
        //gap:10px;

        .close {
          margin: 10px 10px 10px 0;
        }
      }

      .divider{
        margin: 10px 0;
      }
    }
  }

}

//
//.integration .statistics {
//  flex: 2;
//  height: 100%;
//  margin: 0 7px 0 0;
//  padding: 5px;
//  /*max-width: 600px;*/
//  min-height: 500px;
//  max-width: 300px;
//}
//
//.statistics .statistic_data {
//  display: flex;
//  flex-direction: row;
//  flex-wrap: wrap;
//}
//
//.statistics .statistic_card {
//  flex: 1;
//  min-width: 250px;
//  height: 100px;
//  display: flex;
//  align-items: center;
//  position: relative;
//  /*margin: 10px;*/
//}
//
//.statistics .value {
//  z-index: 2;
//  margin: 0 0 0 10px;
//  position: absolute;
//  top: 20px;
//
//}
//
//.statistics .title {
//  position: absolute;
//  left: 10px;
//  top: 2px
//
//}
//
//.statistics .icon {
//  position: absolute;
//  right: 10px;
//
//  height: 150px;
//  width: 150px;
//  z-index: 1;
//  color: rgba(0%, 0%, 0%, 0.2);
//}



