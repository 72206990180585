  .MuiDataGrid-cell--withRenderer {
    padding: 0 !important;
  }

  .tableCell {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      background-color: rgba(255, 255, 255, .2);
    }


    .icon {
      width: 24px;
      height: 24px;
    }

    .progressBar {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 7px;
    }
  }

  .weekendCell {
    background-color: rgba(255, 255, 255, .1);
  }