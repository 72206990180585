.wdExport {
  padding: 10px;
  height: 100%;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: row;



  .card {
    margin-bottom: auto;
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    width: 400px;
    min-height: 720px;

  }

}