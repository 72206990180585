.popUpNewComm{

}

.popUpNewComm .header{

}

.popUpNewComm .content{

}

.popUpNewComm .content {
    display: flex;
    flex-direction: column;

}

.popUpNewComm .content .general{
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 10px;

}

.popUpNewComm .content .commercial{
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 10px;


}
