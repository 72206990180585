.purchase_v2 {
  display: flex;
  align-self: flex-end;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;

  .description {
    margin: 0 10px 0 0;

  }

  .money_type {
    height: 40px;
    width: auto;
    margin: 10px 10px 0 0;

  }
}
