.fdlTable {
  //margin: 0 0 0 10px;
  padding-top: 0;
  height: 100%;
  flex: 1;
  order: 2;
  border: 1px solid rgba(81, 81, 81, 1);
  border-radius: 5px;

  table {
    height: 100%;
    width: 100%;
    padding: 10px;


    /* width */
    *::-webkit-scrollbar {
      width: 7px;
      //display: none;
      display: inherit;
    }

    /* Track */
    *::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    /* Handle */
    *::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /*Inverte il colore dell`icona dell`orologio nei timepicker*/
    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }

    thead {
      tr {
        display: block;

      }
    }

    td {
      padding-top: 3px;
      padding-bottom: 3px;
      text-align: center;
      padding-right: 5px;
      padding-left: 5px;
    }

    th {
      text-align: center;

      padding-right: 5px;
      padding-left: 5px;
    }

    .tbody {
      display: block;
      overflow-y: auto; //set tbody to auto
      .tr {
        border-bottom: 1px solid #515151;
        align-items: center;
        justify-content: center;

        color: inherit;
        display: table-row;
        outline: 0;
        vertical-align: middle;
      }

      .td {
        padding-top: 3px;
        padding-bottom: 3px;
        text-align: center;
        padding-right: 5px;
        padding-left: 5px;
        display: table-cell;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.43;
        vertical-align: inherit;
      }
    }

  }
}
