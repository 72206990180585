.WorkList {
  display: flex;
  flex-direction: row;
  align-content: center;
  //padding: 10px;
  height: 100%;
  position: relative;
  z-index: 10;

  .days {
    width: 35%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 240px));
    grid-template-rows: repeat(5, minmax(140px, 200px));
    column-gap: 10px;
    row-gap: 15px;
    padding: 10px;
    position: relative;

    .info {
      height: 100%;
      width: 100%;
      min-width: 150px;
      min-height: 120px;

      .infoContent {
        height: 100%;
        width: 100%;
      }
    }
  }

  .commission {
    position: relative;
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    //align-items: center;
    padding: 10px 10px 10px 10px;
    overflow: scroll;

    .react-contextmenu-wrapper {
      height: 100%;

      .react-contextmenu-wrapper {
        height: auto;

      }
    }
  }

}

@media (max-width: 1390px) {
  .WorkList {
    flex-direction: column;
    padding: 0;

    .days {
      padding: 10px;
      height: 215px;
      display: flex;
      flex-direction: row;
      overflow: scroll;
      width: 100%;
    }

    .commission {
      position: relative;
      width: 100%;
      padding: 10px;
      min-width: 0;
    }
  }
}


.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #255b97;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, .2);
}

.react-contextmenu .react-contextmenu-item:hover {
  background: rgba(255, 255, 255, 0.3);
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: rgba(255, 255, 255, 0.1);

}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  text-decoration: none;
  padding: 7px 15px;
  cursor: pointer;
  user-select: none;
  transition: .2s;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
  margin: 0 10px;
  border-radius: 5px;
}

.react-contextmenu-item span {
  margin-left: 10px;
}
