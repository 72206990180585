.usrComm{
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: scroll;
}

.usrComm .listComm{
    flex: 1 1 20%;
    height: 100%;
    min-width: 250px;
}

.usrComm .listComm .scrollListComm{
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}


.usrComm .infoComm{
    flex: 9 1 80%;
    position: relative;
}