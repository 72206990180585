.usrData {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    padding: 0 10px 0 10px;
  /*  opacity: 0;*/
  /*transition: 0.5s opacity cubic-bezier(0.12, 0.54, 1, 1);*/
}


.fade-in {
  /*opacity: 1;*/
}

.usrData .rawData {
    flex: 1 1 100px;
    display: flex;
    flex-direction: row;
    height: 100px;
    /*position: absolute;*/
    width: 100%;
}

.usrData .rawData * {
    margin: 0 10px 0 0;
}

.usrData .rawData *:last-child {
    margin: 0;
}

.usrData .rawData .cardDataRappr {
    padding: 10px;
    margin: 0 7px 0 0;
    height: min-content;
}

.usrData .rawData .cardDataRappr:last-child {
    padding: 10px;
    margin: 0;
}

.usrData .graph {
    padding: 10px 0 0 0;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    position: static;
    max-height: Calc(100% - 190px);
}

.usrData .divTotOre {
    width: Calc(100% - 1px);
    height: Calc(100% - 1px);

}


.usrData .divTotOre text {
    fill: #FFF !important;
}

