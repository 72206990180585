.workdata{
    display: flex;
    flex-direction: row;
    width: 100%;

    .days{
        width: 35%;
        height: 40%;
        display: grid;
        grid-template-columns: repeat(3, minmax(150px, 240px));
        grid-template-rows: repeat(2, minmax(140px, 200px));
        column-gap: 10px;
        row-gap: 15px;
        padding: 10px;
        position: relative;
    }

    .wt{
        width: 100%;
    }
}

.creation{

}