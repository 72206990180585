.commissions_details {
  min-height: 90%;
  min-width: 90%;

  .content {
    padding: 0;

    .panel {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      width: 100%;
      height: 100%;
      /*padding: 5px;*/

      .general {
        height: 100%;
        margin: 0 7px 0 0;
        //padding: 5px;
        display: flex;
        flex-direction: column;
        /*box-shadow: 0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2) ;*/
        min-height: 500px;

        .title{
          margin: 5px 0 5px 7px;
        }

        .textfield {
          margin: 18px auto 0 10px;
          width: 50%;
        }
      }
    }
  }

  .action {
    display: flex;
    flex-direction: row;

    .leftActions {
      flex: 1;
    }

    .leftActions {
      flex: 1;
    }

    .action_button {
      margin: 10px 10px 0 0;
    }
  }


}

