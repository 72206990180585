.settings {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .category{
    margin: 0px 15px 0 0;
    //width: 500px;
    .title {
      height: 90px;
      background-color: rgba(0, 0, 0, 0.2);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
    }

    .body {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

  }


    .signConteiner {
      margin: 10px 0 0 0;
      padding: 7px;
      width: fit-content;
    }

    .sign {
      /*margin: 7px;*/
      width: 300px;
      height: 100px;
      margin: 10px 0 0 0;
    }

    .signImg {
      width: 300px;
      height: 100px;
      /*-webkit-filter: invert(1);*/
      /*filter: brightness(0) invert(1);*/
      filter: invert(1);
    }

    .password {
      margin: 10px 0 0 0;
    }

  .action {
    position: absolute;
    right: 10px;
    bottom: 10px;
    top: auto;
    left: auto;
    padding: 10px;
    display: flex;
    justify-content: flex-end;

  }
}


.user .bottom {
  margin: 0 0 0 10px;
}

.new_password {


}

.new_password .content {
  display: flex;
  flex-direction: column;
}

/*.themButton{*/
/*    width: 70px;*/
/*    height: 70px;*/
/*}*/

/*.primaryColorTheme {*/
/*    border-right: 50px solid transparent;*/
/*    border-bottom: 50px solid #d32f2f;*/
/*    !*border-bottom-left-radius: 10px;*!*/
/*    border-top-left-radius: 10px;*/
/*    border-bottom-right-radius: 10px;*/
/*    position: fixed;*/
/*}*/
/*.secondaryColorTheme {*/
/*    border-left: 50px solid transparent;*/
/*    border-top: 50px solid #EF6C00;*/
/*    position: fixed;*/
/*    margin-left: 0px;*/

/*    !*border-top-right-radius: 10px;*!*/
/*    border-top-left-radius: 10px;*/
/*    border-bottom-right-radius: 10px;*/
/*}*/


.themeSelector {
  display: flex;
  flex-direction: row;
}



