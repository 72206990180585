.usrCommData {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    padding: 0 10px 0 10px;
    /*  opacity: 0;*/
    /*transition: 0.5s opacity cubic-bezier(0.12, 0.54, 1, 1);*/
    height: 100% ;
}


.fade-in {
    /*opacity: 1;*/
}

.usrCommData .rawData {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100px;
    /*position: absolute;*/
    width: 100%;
}


.usrCommData .rawData * {
    margin: 0 10px 0 0;
}

.usrCommData .rawData *:last-child {
    margin: 0;
}

.usrCommData .rawData .cardDataRappr {
    padding: 10px;
    margin: 0 7px 0 0;
    height: min-content;
}

.usrCommData .rawData .cardDataRappr:last-child {
    padding: 10px;
    margin: 0;
}

.usrCommData .graph {
    flex: 1 ;
    display: flex;
    flex-direction: row;
    position: static;
    height:  Calc(100% - 190px);

}


.usrCommData .graph .graphTypeHour {
    width: 50%;
    min-width: 100px;
    height: 100%;
    min-height: 100px;
}

.usrCommData .graph .graphDetailHour {

    width: 48%;
    min-width: 100px;
    /*height: Calc(100% - 10px);*/
    height: 100%;

    min-height: 100px;
}
