.workToolbar {
  height: 60px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .right {
    height: min-content;
    margin: auto 10px auto auto;

  }

  .left {
    height: min-content;
    margin: auto auto auto 10px;

  }

  .button{
    margin: auto 5px;
  }

  .blink {
    animation: blink-animation 0.7s steps(1) infinite;

  }

  @keyframes blink-animation {
    0% {

    }
    50% {
      background-color: unset;
    }
    //100% {
    //
    //}
  }
}