.day {
    /*width: 200px;*/
    /*height: 150px;*/
    display: flex;
    flex-direction: column;
    align-content: center;
    min-width: 150px;
    min-height: 145px;
    z-index: 15;
    position: relative;
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, .2);
    transition: 0.1s;
    /*width: 100%;*/
    /*height: 100%;*/
    border: 0 solid #e53935;
}

.day:hover {
    box-shadow: 0 0 20px 8px rgba(0, 0, 0, .70);
    z-index: 20;
    transform: scale(1.03) translateY(0);
}

.day .dayNumber {
    display: flex;
    flex-direction: row;
    width: min-content;
}


.day .actDay {
    background-color: #255B97;
}

.selectedDay {
    outline: 3px dotted;
}

.selectedDayError {
    outline: 3px dotted;
}

.day .dayContent {
    height: 100%;
    width: 100%;
    /*z-index: 9;*/

}

.day .dayContent .hover {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.day .dayContent .hover:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

.day .infoDay {
    /*border: 1px solid #FFF;*/
    border-radius: 5px;
    width: 91%;
    margin: 5px 10px 0 10px;
    position: absolute;
    bottom: 10px;
    font-size: 0.8rem;
}

.day .weekBtn {
    position: absolute;
    z-index: 10;
    top: 0px;
}

.day .hide {
    visibility: hidden;
}

.day .backButton {
    left: 3px;
}

.day .nextButton {
    right: 3px;
}

.day .actDayName {
    align-self: flex-end;

}


