.clientDialog {


  .content {
    padding: 0;

    .ratePlan {
      margin: 10px;
      display: flex;
      //flex-direction: column;

      .header {
        display: block;
      }

      .general {

        flex: 1;
        display: flex;
        flex-direction: column;

        .files {
          width: 100%;
          display: flex;
          flex-direction: row;


        }
      }

      .ratePlanSelector{
        margin-bottom: 10px;
      }

      .rangePicker{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .fileInput{
        width: 100%;
      }

      .rate_time_case {
        flex: 1.7;
        min-height: 70vh;
        max-height: 70vh;
        overflow: scroll;
      }
    }

    .settings {
      margin: 10px auto 10px 10px;
      min-height: 70vh;
      max-height: 70vh;
      display: flex;
      flex-direction: column;

      .TextField{
        width: 20%;
        margin-bottom: 10px;
      }

    }

  }

  .action {
    display: flex;
    flex-direction: row;

    .leftActions {
      flex: 1;
    }

    .leftActions {
      flex: 1;
    }

    .action_button {
      margin: 10px 10px 0 0;
    }
  }
}