.rawData{
  width: 100%;
  height: 100%;

  .notConfirmed{
    background-color: #e53935;
  }
  .edited{
    background-color: #e65100;

  }
  .confirmed{
    background-color: #43a047;

  }

}