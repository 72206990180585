.fdlDialog {

  .content {
    padding: 0;
    display: flex;
    flex-direction: column;

    .main {
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      height: 375px; // Imjpostato altrimenti nella selezione dei giorni nel calndario a volte la dimensione del div aumentava (a caso)


      .daysSelection {

        .toggleButton {
          width: calc(100% - 40px);
          display: flex;
          margin: 0 20px;

          & > * {
            flex: 1;
          }
        }

        .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background-color: rgba(240, 248, 255, 0.21) !important;
          color: #ffffff;
        }

        .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          background-color: #FFC107;
          color: #000;
          border-radius: 50px;

        }

        .DayPicker-wrapper {
          padding-bottom: 0;
        }

        .Selectable .DayPicker-Day {
          //border-radius: 0 !important;
        }

        .Selectable .DayPicker-Day--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }

        .Selectable .DayPicker-Day--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }
      }

      .commSelection {
        display: flex;
        flex-direction: column;
        min-width: 350px;

        .listContainer {
          height: 45%;
          max-height: 50%;
          padding: 5px 10px;

          .title{
            padding: 0 10px 3px 7px;
            border-radius: 4px;
            background-color: rgba(0,0,0,0.2);
          }

          .options {
            height: 80%;
            overflow-y: scroll;
            margin: 3px 7px;

          }

          .commission {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2px 15px;
          }
        }

        *::-webkit-scrollbar {
          width: 10px;
          display: inherit;
        }

        *::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 10px;
        }

        /* Handle */
        *::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }

        /* Handle on hover */
        *::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        /*Inverte il colore dell`icona dell`orologio nei timepicker*/
        ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }

        .moveButton {
          display: flex;
          flex-direction: column;
          margin: 10px;
          align-self: center;

          & > * {
            margin-bottom: 10px;
          }
        }
      }

    }

    .additionalData {

      .tabPanel {
        height: 204px;
        width: 100%;
        padding: 20px;

        .mainDescription {
          //margin: 20px 0 10px 20px;
        }

        .table {

          tr {
            td {
              border: solid transparent;
              border-width: 0 10px 0 0;
            }

            .txtInput {
              margin-right: 35px;
              width: 130px;
            }
          }
        }
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.btnGrpAction {
  padding: 2px;

}

.rainbow_1 {
  position: relative;
  z-index: 4;
  border-radius: 10px;
  overflow: hidden;
  border: 0px;
}

.rainbow_1::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -8%;
  top: -350%;
  width: 300px;
  height: 300px;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
  animation: rotate 4s linear infinite;
}

.rainbow_1::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #424242;
  //border-radius: 5px;
}

//.rainbow_1:hover {
//  border: 0px;
//}

//.SelectedWeekExample .DayPicker-Month {
//    border-collapse: separate;
//}
//
//.SelectedWeekExample .DayPicker-WeekNumber {
//    outline: none;
//}
//
//.SelectedWeekExample .DayPicker-Day {
//    outline: none;
//    border: 1px solid transparent;
//}
//
//.SelectedWeekExample .DayPicker-Day--hoverRange {
//    //background-color: #EFEFEF !important;
//}
//
//.SelectedWeekExample .DayPicker-Day--selectedRange {
//    //background-color: #fff7ba !important;
//    border-top-color: #FFEB3B;
//    border-bottom-color: #FFEB3B;
//    border-left-color: #fff7ba;
//    border-right-color: #fff7ba;
//}
//
//.SelectedWeekExample .DayPicker-Day--selectedRangeStart {
//    background-color: #FFEB3B !important;
//    border-left: 1px solid #FFEB3B;
//}
//
//.SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
//    background-color: #FFEB3B !important;
//    border-right: 1px solid #FFEB3B;
//}
//
//.SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
//.SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
//    border-radius: 0 !important;
//    color: black !important;
//}
//
//.SelectedWeekExample .DayPicker-Day--hoverRange:hover {
//    border-radius: 0 !important;
//}



