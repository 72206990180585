.wtTask {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 5px 0 5px 0;
  align-items: center;
  width: 100%;
  position: relative;
  min-width: 0;


  .group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }
}

@media (max-width: 900px) and (min-width: 691px) {
  .wtTask {
    flex-wrap: nowrap;

    .group {
      flex-wrap: nowrap;
    }
  }
}

@media (max-width: 770px) and (min-width: 691px) {
  .wtTask {
    flex-wrap: wrap;

    .group {
      margin: 7px 0 0 0;
      flex-wrap: nowrap;
    }
  }
}


@media (max-width: 690px) {
  .wtTask {
    flex-wrap: wrap;

    .group {
      margin: 7px 0 0 0;
      flex-wrap: nowrap;
    }
  }

}
