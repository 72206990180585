.monthPicker {
  margin: 10px 0 !important;

  .text {
    width: 300px;
    height: 40px;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    align-items: center;

    .icon {
      //height: 45px;
      height: 100%;
    }

    .value {
      margin: auto 0 auto auto;
    }
  }
}

.popper {
  padding: 3px 0;
  z-index: 99999;
  //background-color: #757575;
  background-image: none;

}