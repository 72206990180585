.commissions {
  min-height: fit-content;
  animation: 0.2s;
  margin: 1px;

  &:hover {
    box-shadow: 10px 10px 15px -2px rgba(0, 0, 0, 0.8);
    position: relative;
    z-index: 100;
  }

  .actionArea {
    height: 100%;

    .content {
      height: max-content;

      .paper {
        height: 100%;
        min-height: 87px;
        max-height: 87px;
        padding: 7px;

        .description {
          display: block;
          display: -webkit-box;
          max-width: 200px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}


.reporting {
  border: 1px solid #e040fb;
}

