.themButton {
    margin: 10px;
    position: relative;
    overflow: hidden;
    height: 75px;
    width: 75px;
    border-radius: 10px;
    border: 2px solid #bdbdbd;
    box-shadow: 0 8px 24px 2px rgba(0, 0, 0, .15);
    transition: all .2s ease-in-out;

}

.themButton:hover {
    box-shadow: 0 10px 25px 5px rgba(0, 0, 0, .40);
    z-index: 20;
    transform: scale(1.03) translateY(0);
}

.primaryColorTheme,
.secondaryColorTheme,
.tertiaryColorTheme {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.primaryColorTheme {
    top: calc(-100% - 2px);
    transform: skewY(-45deg);
    transform-origin: right top;
    border-bottom: 2px solid transparent;
}

.secondaryColorTheme {
    top: 2px;
    transform: skewY(-45deg);
    transform-origin: right bottom;
    border-top: 1px solid transparent;
}

.tertiaryColorTheme {
    transform: skewY(0deg) rotate(45deg);
    //transform: matrix(1, 1, -1, 1, 95, 75);
    //transform-origin: right bottom;
    border-top: 1px solid transparent;
    z-index: 9000;
    top: 52px;
    left: 0px;
}
