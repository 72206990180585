.workData {
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin: 0 0 10px 0;
  //background-color: #424242;
  border-radius: 10px;
  min-width: 400px;
  box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, .8);
  //background-color: rgba(255, 255, 255, .08);
  padding: 3px 10px 10px 10px;

  .lockBar {
    //height: 50px;
    margin-top: 10PX;
    //align-content: end;
    display: flex;

    .start {
      flex: 1;
      display: flex;
      align-self: center;

      .text {
        margin-right: 10px;
      }
    }

    .end {
      flex: 1;
      display: flex;
      align-self: flex-end;
      justify-content: end;

    }

  }

  .rows {
    flex: 1 4 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0px 0 0px 0;
      width: 100%;
      position: relative;
      min-width: 0;
      align-items: center;
    }
  }

  .textfield {
    flex: 1;
    width: 100%;
    min-width: 180px;
    //max-width: 400px;
    height: 40px;
    margin: 10px 10px 0 0;
  }

  .textfieldMaxWidth {
    max-width: 315px;
  }

  .timePicker {
    flex: 0;
    min-width: 180px;
    width: 180px;
    margin: 0 10px 0 0;
  }

  .textFieldNote {
    min-width: 233px;
    //width: 100%;
    margin: 10px 10px 0 0;
    max-width: 641px;
    flex: 1;
  }

  .commContDescr {
    flex: 1 10 auto;
    min-width: 0;
  }

  .commDescr {
    flex: 1 10 auto;
    min-width: 0;
    margin: 7px 0 10px 5px;
    overflow-wrap: unset;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }

  .subRow {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    //flex: 1;

  }

  .fsTrs{
   margin-top: 10px;
    margin-right: 10px;
  }

  .trs {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .actionBtn {
    position: relative;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 15px;
  }

  .circButton {
    height: 40px;
    width: 40px;
    margin-top: 10px;
    margin-right: 10px;
    position: relative;
  }

  .circButton:last-child {
    margin-right: 0px;
  }

  .tasks {
    margin: 10px 0 10px 0;
    overflow: hidden;
    transition: height 0.2s;
  }

  .taskShowButton {
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
  }

  .taskShowButton:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
}

@media (max-width: 960px) and (min-width: 691px) {
  .workData {
    .rows {
      .row {
        flex-wrap: wrap;
      }
    }

    .textfield {
      flex: 1;
      //min-width: 189px;
    }

    .subRow {
      //margin: 7px 0 0 0;
    }
  }
}

@media (max-width: 690px) and (min-width: 601px) {
  .workData {
    .rows {
      .row {
        flex-wrap: wrap;
      }
    }

    .textfield {
      flex: 1;
      //min-width: 189px;
    }

    .subRow {
      //margin: 7px 0 0 0;
    }
  }
}


@media (max-width: 600px) {
  .workData {
    .rows {
      .row {
        flex-wrap: wrap;
        flex-direction: column;
      }
    }

    .subRow {
      //margin: 10px 0 0 0;
    width: 100%;
    justify-content: space-between;
    }

    .textfield {
      flex: 1;
      min-width: 150px;
      //margin-bottom: 10px;
    }

    .textfieldMaxWidth {
      max-width: unset;
    }

    .textFieldNote {
      width: 100%;
      margin-right: 0;
      max-width: unset;
    }

    .timePicker {
      flex: 1;
      min-width: 150px;
    }

  }
}
