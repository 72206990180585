.weekCompilationPopUp {
  min-width: 0;

  //.MuiPaper-root {
  //  max-width: 1400px;
  //  width: 1400px;
  //}


  .content {
    width: 1500px;
    display: flex;
    flex-direction: row;
    padding: 15px;

    .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background-color: rgba(240, 248, 255, 0.21) !important;
          color: #ffffff;
        }

    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          background-color: #FFC107;
          color: #000;
          border-radius: 50px;

        }

        .DayPicker-wrapper {
          padding-bottom: 0;
        }

        .Selectable .DayPicker-Day {
          //border-radius: 0 !important;
        }

        .Selectable .DayPicker-Day--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }

        .Selectable .DayPicker-Day--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }


    .daySelection {
      display: flex;
      flex-direction: column;
      padding: 2px;

      .weekPicker {
        width: 340px;
        margin-bottom: 10px;
      }
    }

    .wtData {
      padding: 10px;
      min-width: 0;
      width: 1200px;

      .wt {
        background-color: rgba(0, 0, 0, .4);
      }
    }
  }
}


@media (max-width: 1480px) {
  .weekCompilationPopUp {
    .content {
      flex-direction: column;

      .wtData {
        padding: 10px 0;
      }

    }
  }
}

