.userPopUp {

  .avatar {
    z-index: 20;
    background-color: rgba(0, 0, 0, 0);
    color: #FFF;
    border: 2px solid #e0e0e0;

  }

  .popUp {
    position: absolute !important;
    right: 10px !important;
    top: 70px !important;
    left: auto !important;
    bottom: auto !important;
    z-index: 3000;
    width: 300px;
    transition: 0.3s !important;
    overflow: hidden;

    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      height: 200px;

      .username {
        z-index: 20;
      }

      .logoutUser {
        position: absolute;
        bottom: 20px;
        z-index: 10;
      }

      .email {
        font-size: 0.8rem;
        color: #dbdbdb;
        z-index: 20;
      }
    }
  }

  .popUpOpen {
    height: 200px;
  }

  .popUpClose {
    height: 0;
    padding: 0;
  }
}
