.mng_comm {
  display: flex;
  height: 100%;
  padding: 7px;


  .commission {
    flex: 3;
    overflow-y: scroll;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 0.3fr));
    grid-auto-rows: max-content;
    grid-gap: 5px;
    padding: 0 0 0 7px;
  }

  .speedDial {
    z-index: 9999;
    position: fixed;
    right: 10px;
    bottom: 10px;
  }


  .filter {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;


    .advanced_filter {
      position: relative;
      flex: 0 1 min-content;
      padding: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 7px;
    }

    .container_list_clients {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 5px 5px 0 5px;
      overflow: auto;

      .client_button {
        //width: 280px;
        margin: 0 0 10px 0;
        background-color: rgba(255,255,255,.1);
        border-radius: 5px;


        .client_code {
          margin: 3px 8px;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .client_button:last-child {
        margin: 0 0 0 0;
      }

      .client_button .MuiPaper-root:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

}

.mainListaComm {
  height: 100%;
  width: 100%;
}

.listaCommMainContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 0.3fr));
  grid-auto-rows: max-content;
  grid-gap: 5px;
  padding-left: 7px;
  /*float: left;*/
  overflow-y: scroll;
  height: 100%;
  /*display: table-caption;*/
  /*margin-top: 10px;*/
}

.filterList {
  display: flex;
  margin-bottom: 10px;
}

.filterList .test {
  width: 265px;
  height: 40px;
}

.filterList .test ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.listCli {
  float: left;
  overflow-y: scroll;
  height: 100%;
  min-width: 163px;

}

.listCli:hover {
  overflow-y: scroll;
}

.listComm {
  float: left;
  position: relative;
  overflow-y: scroll;
  height: 86%;
  padding-bottom: 7px;

}

.listComm:hover {
  overflow-y: scroll;
}

.taskTable {
  margin: 10px;
  height: auto;
  width: 1000px;
  min-height: 300px;
}

.taskGantt {
  margin: 10px;
  height: auto;
  width: 1000px;
  min-height: 200px;
}