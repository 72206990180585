.workDataCompilation {

  .toolbar {

    .legend {
      margin: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      align-items: center;
      height: 40px;

      .iconContainer {

        .icon {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}