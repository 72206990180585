.signUp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .signUpBody {
    min-height: 35%;
    border: 1px solid rgba(158, 158, 158, 0.5);
    box-shadow: 3px 3px 15px 4px #34D6D8;
    border-radius: 10px;
    display: flex;
    position: relative;
    margin: 50px;
    flex-direction: row;


    .logo {
      flex: 1 1 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        max-width: 300px;
      }
    }

    .auth_form {
      position: relative;
      flex: 1 1 50%;
      width: min-content;
      min-width: 350px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .text_field {
        max-width: 300px;
        margin: 0 0 10px 0;
      }


      .action {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 10px 20px 10px 20px;
        .login_button {
          //width: 90%;
          //margin: 10px 10px 10px 10px;
        }
      }

      .registration_button {
        display: inline-block;
        //position: absolute;
        left: 30px;
      }
    }
  }
}

@media (max-width: 900px) {
  .signUp .signUpBody {
    flex-direction: column;

    .auth_form {
      width: 100%;
    }

  }


}