*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    /*Firefox hide scrollbar*/
    scrollbar-width: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #202125 inset !important;
}

/* width */
*::-webkit-scrollbar {
    width: 10px;
    display: none;
}

/* Track */
*::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/*Inverte il colore dell`icona dell`orologio nei timepicker*/
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.App {
    /*background-color: #202125;*/
    height: 100%;
}

.boot {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    /*background-color: #202125;*/

}

.boot img {
    width: 25%;
    height: 25%;
    animation: heartbeat 3s infinite;
    transform: scale(1.1);
    animation-direction: alternate;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}
