.worktime {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  //padding: 5px 0 5px 0;
  align-items: center;
  width: 100%;
  position: relative;
  min-width: 0;

}

@media (max-width: 900px) and (min-width: 691px) {
  .worktime {
    flex-wrap: nowrap;

    .timePicker {
      flex: unset;
    }

  }
}

@media (max-width: 770px) and (min-width: 691px) {
  .worktime {
    flex-wrap: wrap;

    .timePicker {
      //width: 100px;
      flex: unset;
    }

  }
}


@media (max-width: 690px) {
  .worktime {
    flex-wrap: wrap;

    .timePicker {
      min-width: 10px;
      width: 120px;
      flex: unset;
    }

    .subRow {
      margin: 0;
      width: auto;
    }

  }

}
