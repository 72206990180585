.clientCard {
    min-height: fit-content;
    box-sizing: border-box;
    border: 1px solid #fb8c00;
}

.clientCard .actionArea {
    height: 100%;
}

.clientCard .header {

}

.clientCard .content {

}

.clientCard .paper {
    height: 100%;
    padding: 7px;
}


.popUpClientCard {

}

.popUpClientCard .header {

}

.popUpClientCard .content {
    padding: 0;
    display: flex;
    min-height: max-content;
}

.popUpClientCard .general {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 7px;
    min-height: max-content;
}

.popUpClientCard .general .textField{
    width: 100%;
    max-width: 300px;
    margin-top: 10px;
    min-height: max-content;


}

.popUpClientCard .contacts {
    flex: 1;
}