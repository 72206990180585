.usrCommission {
    margin: 0 0 10px 0 !important;
    min-width: 240px;
}

.usrCommission:last-child {
    margin: 0 !important;;
}

.usrCommissionPaper {
    padding: 10px;
    width: 100%;

}


.usrCommissionPaper:hover {
background-color: rgba(255, 255, 255, 0.28);
}


.usrCommission .code {
    font-size: 1.8rem;
    text-align: start;

}

.usrCommission .description {
    font-size: 0.8rem;
    color: #e0e0e0;
    text-align: start;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}