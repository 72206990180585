.leave {
  display: flex;
  flex-direction: row;
  padding: 7px;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;


  .circButton {
    height: 40px;
    width: 40px;
  }
}