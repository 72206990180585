.layout {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  .appBar {
    z-index: 1000;
    background-image: unset;

    .toolBar {
      flex-direction: row;
      display: flex;
      width: 100%;
      align-items: center;

      .toolBarLeft {
        flex: 1;
        display: flex;
        flex-direction: row;
        order: 1;
        align-items: center;
        position: relative;

        .menuBtn {
          position: absolute;
        }

        .menuBtnHide {
          display: none;
        }
      }

      //.toolBarCenter {
      //  flex: 3;
      //  display: flex;
      //  flex-direction: row;
      //  order: 2;
      //  align-items: center;
      //}

      .toolBarRight {
        flex: 9;
        display: flex;
        flex-direction: row;
        order: 3;
        align-items: center;
        justify-content: flex-end;

        .generate_fdl {
          position: relative;
          order: 5;
          margin-left: 10px;
        }

        .divDatePicker {
          order: 10;
          margin-left: 10px;
        }

        .avatar {
          order: 20;
          margin: 0 0 0 5px;
        }
      }
    }
  }

  .drawerOpen {
    width: 270px;
    overflow-x: hidden;

  }

  .layoutContent {
    height: calc(100% - 64px);
    flex: 1;
    position: relative;
    top: 64px;
    width: calc(100% - 60px);
    overflow: scroll;

  }

  .drawerClose {
    width: 60px;
    overflow-x: hidden;
  }


}

.drawer {
  height: 100%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  background-color: unset;
  border: unset;
  z-index: 100 !important;

  .MuiDrawer-paper {
    position: relative;
    background-color: unset;
    border: unset;
    justify-content: center;
    box-shadow: unset;
    overflow-x: hidden;

  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(7px);
    z-index: 999;

  }

  .navLink {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    margin: 3px 7px 3px 7px;
    width: 280px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }
  }

  .navLinkIcon {
    margin: 0 20px 0 0;
    height: 27px;
    width: 27px;
    position: relative;

    svg{
      position: absolute;
      left: 2px;
      top: 1px;
      height: 24px;
      width: 24px;
    }
  }
}


//.sendfile {
//  order: 1;
//  position: relative;
//}
//
//.sendfile .buttonSendFile {
//  height: 40px;
//  width: 40px;
//  color: #F57C00;
//  background-color: #ffffff;
//  z-index: 2;
//}
//
//.sendfile .animationSendFile {
//  position: absolute;
//  top: -6px;
//  left: -6px;
//  z-index: 1;

.fdlErrorsDialog{

  .content{

    .row{
      display: flex;
      flex-direction: row;
      //height: 50px;
      //justify-content: center;
      align-items: center;
      align-content: center;
      //border: 1px solid #e0e0e0;
      border-radius: 5px;
      margin: 7px 0;
      padding: 3px 5px;

      .icon{
        margin-right: 10px;
        height: 25px;
        width: 25px;
      }

      .date{
        margin-right: 10px;
      }

      .ErrorInfo{
        color: #FFFFFF;
      }

      .details{
        //margin-left: 125px;
        font-size: 0.7rem;
        color: #f5f5f5;
      }
    }
  }
}

