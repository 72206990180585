.yearPicker {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0) !important;
  //margin: 10px 0 0 0;
  width: 800px;
  display: flex;
  flex-direction: row;

  .year {
    margin-right: 10px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      width: 400px;
    }


    .yearList {
      width: 400px;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(3, 50px);
      grid-gap: 10px;
      margin-top: 10px;
      //margin-bottom: 10px;

      .yearCard {
        width: 100%;
        height: 100%;
        margin: 0 10px 10px 0;
        text-align: center;
        line-height: 40px;
        //border: 1px solid;

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  .monthList {
    width: 400px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(4, 50px);
    grid-gap: 10px;
    margin-left: 10px;
    //margin-top: 10px;

    .monthCard {
      width: 100%;
      height: 100%;
      margin: 0 10px 10px 0;
      text-align: center;
      line-height: 40px;
      //border: 1px solid;
      text-transform: capitalize;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

}