.detailPanelContent{
  margin: 2% 5%;
  padding: 20px;
  //height: 100%;

  .firstRow{
    display: flex;
    flex-direction: row;
    gap: 40px;
    flex-wrap: wrap;
  }

  .table{
    flex: 1;
    height: 200px;
    margin-bottom: 40px;
  }
}