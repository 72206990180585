/*input*/
.travel_v2 {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-end;
    width: 100%;
    /*justify-content: flex-end;*/
    /*padding: 0 95px 0 0;*/
    align-items: center;
    //padding: 5px 0 5px 0;
    margin-top: 10px;

    .timePicker{
        width: 111px;
    }

    .km{
        width: 50px ;
        min-width: 50px;
    }

    .autostrada {
        height: 40px;
        width: auto;
        margin-right: 10px;
        margin-top: 10px;

    }

}
