.weekCompilationPopUp {
  min-width: 0;

  .MuiPaper-root {
    //max-width: 1400px;
    //width: 1400px;
  }


  .content {
    display: flex;
    flex-direction: row;
    padding: 15px;


    .daySelection {
      display: flex;
      flex-direction: column;
      padding: 2px;

      .weekPicker {
        width: 340px;
        margin-bottom: 10px;
      }
    }

    .wtData {
      padding: 10px;
      min-width: 0;
      width: 100%;

      .wt {
        background-color: rgba(0, 0, 0, .4);
      }
    }
  }
}


@media (max-width: 1480px) {
  .weekCompilationPopUp {
    .content {
      flex-direction: column;

      .wtData {
        padding: 10px 0;
      }

    }
  }
}

