
.statisticsBox {
    flex: 1;
    min-width: 250px;
    /*max-width: 400px;*/
    display: flex;
    align-items: center;
    position: relative;
    /*margin: 10px;*/
    transition: 0.2s !important;
    overflow: hidden;
    z-index: 10;
}

.statisticsBoxExpanded {
    /*height: 270px;*/
    z-index: 1000;
    position: relative;
    /*box-shadow: 0px 0px 10px 5px rgba(180,180, 180, 0.5) !important;*/


}

.statisticsBoxNormal {
    height: 100px;

}

.statisticsBox .statistics_box_value {
    z-index: 2;
    margin: 0 0 0 10px;
    position: absolute;
    top: 20px;

}

.statisticsBox .statistics_box_icon {
    position: absolute;
    top: 0;
    right: 15px;
    height: 100px;
    width: 100px;
    z-index: 1;
    color: rgba(0%, 0%, 0%, 0.3);
}

.statisticsBox .statistics_box_title {
    position: absolute;
    left: 10px;
    top: 2px;
    z-index: 2;


}

.statisticsBox .expandIcon {
    position: absolute;
    top: 70px;
    right: 0;
}

.statisticsBox .extra {
    position: absolute;
    top: 101px;
    left: 7px;
    /*padding: 10px 0 10px 0;*/
    /*border: 1px solid #e7e7e7;*/
    margin: 0 10px 0 10px;
    border-radius: 10px;
    width: 90%;
    /*width: 340px;*/
}

.statisticsBox .extra .inlineValue {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 3px 10px 3px 10px;
}

.statisticsBox .extra .extraText {
    text-align: start;
}

.statisticsBox .info {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100000;

}