.popUpNewCli{

}

.popUpNewCli .header{

}

.popUpNewCli .content{

}

.popUpNewCli .content {
    display: flex;
    flex-direction: row;

}

.popUpNewCli .content .general{
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 10px;

}

.popUpNewCli .content .commercial{
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 10px;


}
