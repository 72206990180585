.wdFdlExport {
  .filterBar {
    display: flex;
    flex-direction: row;

    .commissionFilter{
      width: 300px;
      margin: 10px;
    }
  }
}