.fdlMng {
  padding: 10px;
  height: 100%;

  display: flex;
  flex-direction: column;

  .rightBar {
    order: 1;
    height: 282px;
    width: 100%;
    //padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;

    .action {
      padding: 10px;
      display: flex;
      flex-direction:   column;


      .button {
        margin-right: 10px;
        width: 190px;
        //height: 50%;

        &:last-child {
          margin-right: 0;

        }
      }
    }

    .export{
      margin: 10px 0 0 0;
    }

    .textInput {
      flex: 1;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;

      .textFilter {

        min-width: 200px;
        //width: 400px;
        max-width: 600px;

      }

            .checkFilters {
        display: flex;
        flex-direction: row;

        .coupleCheckFilters {

          display: flex;
          flex-direction: column;


          .check {
            flex: 0.75;
          }

          .checkNot {
            flex: 1;
          }
        }
      }
    }

    .verticalContainer {

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;
      //align-items: center;


    }
  }


}